

img {
    width: 25%;
}

div h3 {
    color: #f15a23;
    text-align: center;
    margin-bottom: 1rem;
}

.disp1 {
    display: flex;
    justify-content: space-between;
    color: #0076ad;
}
.disp2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.over {
    margin-bottom: 8rem;
    line-height: 1.1;
}
.over1 {
    margin-bottom: 4.5rem;
    line-height: 1.1;
}
.over2 {
    margin-bottom: 6.5rem;
    line-height: 1.1;
}
.over5 {
    margin-bottom: 4rem;
}

@media screen and (max-width: 1200px) {
    .over,.over1,.over2,.over3,.over4 {
        font-size: 14px;
    }
}

h5 {
    border-bottom: 1px solid #0076ad80;
}

.pos {
    position: absolute;
    left: 35%;
}

.hover:hover {
    transform: scale(.9);
    outline-offset: 10px;
    box-shadow: 5px 5px 10px #f15a23 ;
    outline: 10px solid #0076ad;
    z-index: 100;
}

.trans {
    position: absolute;
    transition: all .5s;
    transform: scale(.6);
    border-radius: 10px;
}


.position{
    position: absolute;
    width: 50%;
}

.row{
    display: flex;
}
.reverse {
    display: flex;
    flex-direction: row-reverse;
}

.col_1 {
    top: 15%;
    left: 4%;
}

.col_2_1 {
    top: 17%;
    right: 7%;
    z-index: 1;
}

.col_2_2 {
    top: 12%;
    right: 17%;
}
.col_2_3 {
    top: 28%;
    right: 15%;
}
.col_2_4 {
    top: 32%;
    right: 3%;
}


.col_3 {
    right: 4%;
    top: 75%;
}

.col_4_1 {
    left: 2%;
    top: 90%;
}
.col_4_2 {
    left: 20%;
    top: 90%;
}
.col_4_3 {
    left: 2%;
    top: 120%;
}
.col_4_4 {
    left: 20%;
    top: 120%;
}
.col_4_5 {
    left: 11%;
    top: 105%;
}

.col_5 {
    left: 6%;
    top: 185%;
}

.col_6_1 {
    right: 10%;
    top: 195%;
    z-index: 1;
}
.col_6_2 {
    right: 6%;
    top: 180%;
}
.col_6_3 {
    right: 16%;
    top: 185%;
}

.col_7 {
    right: 6%;
    top: 250%;
}

.col_8_1 {
    left: 2%;
    top: 240%;
}
.col_8_2 {
    left: 18%;
    top: 240%;
}

.col_9 {
    left: 6%;
    top: 290%;
}

.col_10 {
    right: 12%;
    top: 280%;
}

.col_11 {
    right: 6%;
    top: 330%;
}

.col_12 {
    left: 12%;
    top: 320%;
}

.col_13 {
    left: 6%;
    top: 370%;
}

.col_14 {
    right: 12%;
    top: 360%;
}

.col_15 {
    right: 6%;
    top: 405%;
}

.col_16 {
    left: 12%;
    top: 400%;
}
.col_17 {
    left: 6%;
    top: 450%;
    height: 45vh;
}

.col_18 {
    right: 12%;
    top: 440%;
}

@media only screen and (max-width: 500px) {
    img {
        width: 50%;
    }

    .hover {
        outline-offset: 5px;
        box-shadow: 5px 5px 10px #0076ad ;
        outline: 5px solid #f15a23;
    }
    .hover:hover {
        transform: scale(.6);
    }
/* 
    .position {
        font-size: .8rem;
        line-height: 1.2;
        width: 90%;
    } */

    .col_2_1 {
        top: 7%;
        left: 5%;
    }
    .col_2_2 {
        top: 7%;
        left: 45%;
    }
    .col_2_3 {
        top: 28%;
        left: 5%;
    }
    .col_2_4 {
        top: 28%;
        left: 45%;
    }

    .col_1 {
        top: 55%;
        left: 5%;
        font-size: .8rem;
        line-height: 1.2;
        width: 90%;
    }

    .col_4_1 {
        top: 105%;
        left: 5%;
    }
    .col_4_2 {
        top: 105%;
        left: 45%;
    }
    .col_4_3 {
        top: 125%;
        left: 5%;
    }
    .col_4_4 {
        top: 125%;
        left: 45%;
    }
    .col_4_5 {
        top: 145%;
        left: 25%;
    }

    .col_3 {
        top: 172%;
        left: 5%;
        width: 90%;
    }
    .over {
        margin-bottom: 12rem;
        font-size: .8rem;
        line-height: 1.2;
    }
    .over1 {
        margin-bottom: 7rem;
        font-size: .8rem;
        line-height: 1.2;
    }

    .padd {
        width: 30%;
    }
    .over2 {
        margin-bottom: 8rem;
        font-size: .8rem;
        line-height: 1.2;
    }
    .over3 {
        margin-bottom: 2.5rem;
        font-size: .8rem;
        line-height: 1.2;
    }
    .over4 {
        margin-bottom: 6.5rem;
        font-size: .8rem;
        line-height: 1.2;
    }

    .col_6_1 {
        top: 305%;
        left: 5%;
    }

    .col_6_2 {
        top: 305%;
        left: 45%;
    }
    .col_6_3 {
        top: 325%;
        left: 25%;
    }
    .col_5 {
        top: 349%;
        left: 5%;
        width: 90%;
        font-size: .8rem;
        line-height: 1.2;
    }

    .col_8_1 {
        top: 400%;
        left: 5%;
    }
    .col_8_2 {
        top: 400%;
        left: 45%;
    }

    .col_7 {
        top: 425%;
        left: 5%;
        width: 90%;
        font-size: .8rem;
        line-height: 1.2;
    }

    .col_10 {
        top: 455%;
        left: 25%;
    }

    .col_9 {
        top: 480%;
        left: 5%;
        width: 90%;
        font-size: .8rem;
        line-height: 1.2;
    }
    .col_12 {
        top: 502%;
        left: 25%;
    }

    .col_11 {
        top: 526%;
        left: 5%;
        width: 90%;
        font-size: .8rem;
        line-height: 1.2;
    }
    .col_14 {
        top: 550%;
        left: 25%;
    }

    .col_13 {
        top: 574%;
        left: 5%;
        width: 90%;
        font-size: .8rem;
        line-height: 1.2;
    }
    .col_16 {
        top: 594%;
        left: 25%;
    }

    .col_15 {
        top: 618%;
        left: 5%;
        width: 90%;
        font-size: .8rem;
        line-height: 1.2;
    }
    .col_18 {
        top: 650%;
        left: 25%;
    }

    .col_17 {
        top: 674%;
        left: 5%;
        width: 90%;
        font-size: .8rem;
        line-height: 1.2;
    }
}

@media only screen and (max-width: 360px) {

}