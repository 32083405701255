.bg_width {
    width: 50% !important;
    height: 50vh;
}


.btn_orange button{
    background-color: #f15a23 !important;
}


.size {
    width: 90% !important;
    left: 5%;
    height: 55%;
}
.inner {
    border-radius: 10px;
    height: 74vh;
}


.product_btn {
    position: absolute;
    top: 65%;
    right: 15%;
    background-color: #eee;
    border-radius: 10px;
    padding:.5rem 1.5rem;
    color: #333;
    text-decoration: none;
    outline-offset: 5px;
    outline: 5px solid #999;
}

.product_btn:hover {
    transition: all .5s;
    outline:5px solid #f15a23;
    box-shadow: 5px 5px 10px #0076ad;
    color: #0076ad;
}

.items {
    width: 75%;
    left: 12.5%;
    box-shadow: 10px 10px 15px #f15a23;
    transition: all 1s;
}

.bg_color {
    background-color: #cfffff;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

@media only screen and (max-width: 500px) {
    .col_1 {
        display: flex;
        flex-direction: column;
    }
    .bg_width {
        width: 100% !important;
        height: 30vh;
    }

    .product_btn {
        top: 98%;
        right: 32.5%;
        padding:.25rem .5rem;
        outline-offset: 5px;
        outline: 3px solid #f15a23;
    }

    .inner{
        height: 27.5vh;
    }
}

@media only screen and (max-width: 360px) {
    .product_btn {
        right: 27.5%;
    }

    .inner {
        height: 20vh;
    }
}