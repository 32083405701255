.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #555;
    margin-top: 3rem;
    margin-bottom: 3rem;
    justify-content: space-around;
}
.content {
    margin-top: 6rem;
}


.kelly {
    position: relative;
    top: 14px;
    color:#003b40 ;
    font-weight: 700; 
    /* transition: all .5s; */
    /* animation: moveInTop1 .25s ease-out .5s; */
    /* animation-fill-mode: backwards; */
}

.pharma {
    position: relative;
    top: 14px;
    color: #f15a23;
    font-weight: 700;
}

.a_logo {
    width: 80px;
}

.a_text1,.a_text2,.a_text3,.a_text4 {
    font-weight: 600;
    font-size: 1.3rem;
}

.a_text1 {
    position: absolute;
    top: 43%;
    left: 22%;
}
.a_text2 {
    position: absolute;
    left: 19%;
    top: 47.5%;
    
}
.a_text3 {
    position: absolute;
    left: 19%;
    top: 52%;
}
.a_text4 {
    position: absolute;
    left: 18%;
    top: 56.5%;
}

.map {
    position: relative;
    border: 2px solid #f15a23;
    border-radius: 5px;
    top: 10rem;
    height: 50vh;
    width: 40vw;
}

.map:hover {
    outline-offset: 10px;
    box-shadow: 5px 5px 10px #f15a23;
    outline: 10px solid #0076ad;
}

@media only screen and (max-width: 500px) {

    .container {
        display: flex;
        flex-direction: column;
    }

    .content {
        margin-bottom: 4rem;
    }
    .a_logo {
        width: 60px;
    }
    .kelly{
        font-size: 1.3rem;
        top: 10px;
    }
    .pharma {
        font-size: 1.3rem;
        top: 10px;
    }

    .a_text1 {
        position: absolute;
        top: 32%;
        left: 43%;
    }
    .a_text2 {
        position: absolute;
        left: 35%;
        top: 36%;
        
    }
    .a_text3 {
        position: absolute;
        left: 35%;
        top: 40%;
    }
    .a_text4 {
        position: absolute;
        left: 32%;
        top: 44%;
    }

    .map{
        width: 100%;
        height: 35vh;
        top: 8rem;
        outline-offset: 5px;
        outline: 5px solid #0076ad;
    }
    .map:hover {
        outline-offset: 0;
        outline: none;
        box-shadow: none;
    }

}

@media only screen and (max-width: 360px) {
    .kelly{
        font-size: 1rem;
    }
    .pharma {
        font-size: 1rem;
    } 
    .a_text1,.a_text2,.a_text3,.a_text4 {
        font-weight: 500;
        font-size: 1rem;
    } 
    .map {
        width: 80vw;
        height: 30vh;
    }     
}