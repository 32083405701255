nav {
    opacity: 0.9;
    box-shadow: #f15a23 1px 5px 5px;
}
.img {
    position: absolute !important; 
    width: 70px !important;
    top: -5px;
    left: 0 !important;
    transition: all .2s;
}
.img:hover {
    transform: rotate(360deg);
}

.pos {
    position: relative;
    top: 5%;
}

@media screen and (max-width: 990px) {
    .btnNav {
        font-size: 1.2rem;
        margin: 24px 0;
        padding: .25rem 0;
        text-transform: uppercase;
        font-weight: 600;
    }
    
    .btnNav:hover{
        background-color: #0076ad;
        outline: 5px solid #f15a23;
        border-radius: 5px;
        outline-offset: 5px;
        transition: all .2s;
    }
    .btnNav a:hover {
        color:#eee !important;
        transition: all .2s;
    }
}

.justify {
    justify-content: flex-end !important;
}

@keyframes moveInLeft {
    0% {
        transform: translateX(-10rem);
        opacity: 0;
    }

    80% {
        transform: translateX(2rem);
    }

    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

.color_primary {
    position: relative;
    left: 56px;
    color: #0076ad !important;
    font-weight: 700 ;
    text-transform: uppercase;
}

.moveinleft {
    animation-name: moveInLeft;
    animation-duration: 2s;
    animation-timing-function:ease-out ;
}


/* .header {
    color: #0076ad;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700 ;
} */
/* .bgcolor {
    background-color: #f15a23;
} */
.tcolor {
    color: #f15a23;
}

.btnNav {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
}

.actlink:hover,
.actlink:active,
.actlink:focus {
    color: #f15a23 !important;
}

/* .lnk {
    color: #777 !important;
}

.lnk:hover {
    color: #444 !important;
} */

