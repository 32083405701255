
.aboutImg1 {
    margin-top: 5rem;
}

.container  {
    height: 100vh;
}

.aboutImg1 img {
    border-radius: 5px;
    width: 90%;
    height: 90vh;
    margin-left: 5%;
    margin-bottom: 2rem;
    border: 1px solid #f15a23cc;
    transition: all .1s;
}
.aboutImg1 img:hover  {
    border: none;
    box-shadow: 10px 10px 15px #0076ad;
}

.about_main_content1 {
    border-radius: 5px;
    position:absolute;
    top: 5rem;
    left: 5%;
    width: 45%;
    height: 90vh;
    color: #333;
    background-color: #ffffffaa;
    padding: 2rem;
    
}

.aboutImg2 img {
    position: absolute;
    border-radius: 5px;
    width: 90%;
    margin-left: 5%;
    height: 90vh;
    top: 55rem;
    margin-bottom: 2rem;
    border: 1px solid #f15a23cc;
}
.aboutImg2 img:hover  {
    border: none;
    box-shadow: 10px 10px 15px #0076ad;
}


.about_main_content2 {
    border-radius: 5px;
    position:absolute;
    height: 90vh;
    line-height: 1.1;
    top: 55rem;
    right: 5%;
    width: 45%;
    color: #333;
    background-color: #ffffff70;
    padding: 5rem 2.5rem;
    padding-top: 2rem;
}

.header_main {
    color: #f15a23;
    text-align: center;

}


.header_tertiary {
    color: #0076ad;
}



@media only screen and (max-width: 500px) {
    .container {
        height: 90vh;
    }
    .about_main_content1 {
        width: 90%;
        height: 80vh;
        font-size: .8rem;
        line-height: 1.5;
        padding-left: 1rem;
        padding-right: 1rem;
        box-shadow: 10px 10px 15px #0076ad;
    }
    .about_main_content2 {
        width: 90%;
        height: 80vh;
        font-size: .8rem;
        line-height: 1.5;
        padding-left: 1rem;
        padding-right: 1rem;
        box-shadow: 10px 10px 15px #0076ad;
    }
    .aboutImg1 img {
        height: 80vh;
    }
    .aboutImg2 img {
        height: 80vh;
    }
}


@media only screen and (max-width: 360px) {

    .about_main_content1 {
        line-height: 1.3;
    }
    .about_main_content1 p {
        margin-bottom: .5rem;
    }
    .about_main_content2 {
        line-height: 1.3;
        
    }
    .about_main_content2 p {
        margin-bottom: 0;
    }
}